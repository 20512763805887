document.addEventListener('DOMContentLoaded', e => {
    const $hamburgerButton = document.querySelector('[data-hamburger-button]');
    const $headerMenu = document.querySelector('[data-header-menu]');
    const toggleMenu = () => {
        document.body.classList.add('is-loaded');
        document.body.classList.toggle('no-scroll');
        $hamburgerButton.classList.toggle('is-active');
        $headerMenu.classList.toggle('is-active');
    };

    $hamburgerButton.addEventListener('click', function (e) {
        e.preventDefault();
        toggleMenu()
    });
    $headerMenu.querySelectorAll('a.item').forEach(link => {
        link.addEventListener('click', function (e) {
            toggleMenu()
        })
    })
});

window.addEventListener('load', e => {
    document.body.classList.add('is-loaded');
});