const $video = document.querySelector('[data-video]');
const $button = $video.querySelector('[data-play-button]');
const $iframe = $video.querySelector('.iframe-video');

function onYouTubeIframeAPIReady() {
    player = new YT.Player('iframe-video', {
        events: {
            'onReady': onPlayerReady,
        }
    });
}

function onPlayerReady(event) {
    $button.addEventListener('click', function (e) {
        e.preventDefault();
        event.target.playVideo();
        $iframe.classList.add('active');
    });
}

$(function() {

    var $video = $('.video-player');
    var $window = $(window);

    $window.scroll(function() {
        var $topOfVideo = $video.offset().top;
        var $bottomOfVideo = $video.offset().top + $video.outerHeight();

        var $topOfScreen = $window.scrollTop();
        var $bottomOfScreen = $window.scrollTop() + $window.innerHeight();

        if(
            (($bottomOfVideo > $topOfScreen + 300) && ($topOfVideo < $bottomOfScreen - 300))
        ) {
            $video[0].play();
        } else {
            $video[0].pause();
        }
    });
});