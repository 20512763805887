/**
 *  Container with items [data-pagination-update=" PAGINATION ID "]
 *  Button [data-pagination=" PAGINATION ID "]
 */
const $button = document.querySelectorAll('[data-pagination]');
$button.forEach(button => {
    button.addEventListener('click', function (e) {
        e.preventDefault();
        const paginationId = this.dataset.pagination;
        this.classList.add('loading');
        fetch(this.href)
            .then(res => {
                return res.text()
            })
            .then(text => {
                const parser = new DOMParser();
                const html = parser.parseFromString(text, 'text/html');
                const newContent = html.querySelector(`[data-pagination-update="${paginationId}"]`);
                document.querySelector(`[data-pagination-update="${paginationId}"]`).append(...newContent.childNodes);

                const newPagination = html.querySelector(`[data-pagination="${paginationId}"]`);
                const pagination = document.querySelector(`[data-pagination="${paginationId}"]`);
                if (!newPagination) return pagination.remove();
                pagination.href = newPagination.href;
            })
            .catch(err => console.log(err))
            .finally(() => {
                this.classList.remove('loading');
            });
        return false;
    })
});