function throttle(callback, limit) {
    var tick = false;
    return function () {
        if (!tick) {
            callback.call();
            tick = true;
            setTimeout(function () {
                tick = false;
            }, limit);
        }
    }
}

const $header = document.querySelector('header.header');
const toggleHeaderBlack = () => {
    if (window.scrollY > 120) {
        $header.classList.add('is-dark');
        return
    }
    $header.classList.remove('is-dark')
};
window.addEventListener("scroll", throttle(toggleHeaderBlack, 60));
